import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

import styles from './cta-band.module.scss';

const CTABand = ({ title, text, to, ...props }) => (
  <section className={styles.ctaBand}>
    <div className={styles.ctaWrapper}>
      <h2 className={styles.title}>{title}</h2>
      <Button
        {...props}
        className={styles.button}
        color="white"
        textStyle="upper"
        to={to}
      >
        {text}
      </Button>
    </div>
  </section>
);

CTABand.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default CTABand;
