import React from 'react';
import { Markup } from 'react-render-markup';

import { useInformationData } from '../../hooks/use-information-data';

import Button from '../button/button';

import styles from './button-banner.module.scss';

const ButtonBanner = ({  titleTid, buttonTid, ...props }) => {
  const buttonInformation = useInformationData().filter((item) => item.promotions[buttonTid]) ?? [];
  const titleInformation = useInformationData().filter((item) => item.promotions[titleTid]) ?? [];


  return (
    <section className={styles.buttonBanner}>
      <div className={styles.bannerWrapper}>
        <h2 className={styles.title}>{titleInformation?.[0]?.title}</h2>
        <p className={styles.subtitle}>{titleInformation.subtitle}</p>
        <div className={styles.buttonWrapper}>
          {buttonInformation &&
          buttonInformation.map((item, index) => {
            return (
              <Button
                {...props}
                key={index}
                className={styles.button}
                color="white"
                textStyle="upper"
                to={item.hyperlink.uri}
                target={item.hyperlink.target}
              >
                <div className={styles.buttonIcon}>
                  <Markup markup={item.icon} />
                </div>
                {item.hyperlink.title}
              </Button>
            );
          })
          }
        </div>
      </div>
    </section>
  );
};

export default ButtonBanner;
