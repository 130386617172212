import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Link from '../link/link';

import styles from './media-object.module.scss';

/**
 * The MediaObject displays an image next to some content.
 *
 * @returns {JSX} The MediaObject component.
 */
const MediaObject = ({ alt, className, src, to, title, text, resolutions, sizes }) => {
  const classes = classnames(styles.mediaObject, className);
  return (
    <div className={classes}>
      <div className={styles.media}>
        {resolutions && <Img resolutions={resolutions} alt={alt} />}
        {sizes && <Img sizes={sizes} alt={alt} />}
        {src && <img src={src} alt={alt} />}
      </div>
      <div className={styles.content}>
        {to ? (
          <Link to={to} className={styles.link}>
            <Content title={title} text={text} />
          </Link>
        ) : (
          <Content title={title} text={text} />
        )}
      </div>
    </div>
  );
};

const Content = ({ title, text }) => (
  <React.Fragment>
    <p className={styles.title}>{title}</p>
    <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
  </React.Fragment>
);

MediaObject.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  resolutions: PropTypes.object,
  sizes: PropTypes.object,
  src: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string
};

export default MediaObject;
