import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// Imports the CSS from slick carousel.
import 'slick-carousel/slick/slick.css';
import Button from '../button/button';
import { ReactComponent as Play } from './assets/play.svg';
import { ReactComponent as Pause } from './assets/pause.svg';

// CSS Module styles for the slideshow.
import styles from './hero-slideshow.module.scss';

export default class HeroSlideshow extends Component {
  constructor() {
    super();

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);

    this.state = {
      isPlaying: true
    };
  }

  play() {
    this.setState({ isPlaying: true });
    this.slider.slickPlay();
  }
  pause() {
    this.setState({ isPlaying: false });
    this.slider.slickPause();
  }

  buttons() {
    return this.state.isPlaying ? (
      <Button className={styles.pause} onClick={this.pause}>
        <span className="visually-hidden">Pause</span>
        <Pause className={styles.icon} height="16" width="16" />
      </Button>
    ) : (
      <Button className={styles.play} onClick={this.play}>
        <span className="visually-hidden">Play</span>
        <Play className={styles.icon} height="16" width="16" />
      </Button>
    );
  }

  render() {
    // Settings for react-slick.
    const settings = {
      appendDots: (dots) => (
        <ul className={styles.dots}>
          <li>{this.buttons()}</li>
          {dots}
        </ul>
      ),
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 7500,
      className: styles.slideshow,
      dots: true,
      dotsClass: styles.dots,
      infinite: true,
      nextArrow: <SlickButton>Next Slide</SlickButton>,
      prevArrow: <SlickButton>Previous Slide</SlickButton>,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500
    };

    return (
      <Slider ref={(slider) => (this.slider = slider)} {...settings}>
        {this.props.children}
      </Slider>
    );
  }
}

HeroSlideshow.propTypes = {
  children: PropTypes.node.isRequired
};

// Abstracting this component to prevent currentSlide and slideCount from
// being passed to <button>, which was triggering a warning from React.
const SlickButton = ({ currentSlide, slideCount, ...props }) => {
  return <button {...props}>{props.children}</button>;
};

SlickButton.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  children: PropTypes.node.isRequired
};
