import React from 'react';
import PropTypes from 'prop-types';

import HeroSlide from '../hero-slide/hero-slide';
import HeroSlideshow from '../hero-slideshow/hero-slideshow';
import styles from './hero.module.scss';
import { get } from '../../helpers/hooks-helpers';
import { useCurrentFilter } from '../../hooks/use-current-filter';

const HomepageHero = ({ data }) => {
  const hero = useCurrentFilter(
    get(data, 'HomepageHero.relationships.field_promoted_content', [])
  );

  // Sort events by start date
  hero.sort((a,b) => {
    const dateA = ('date' in a) ? a.date.raw : a.field_date.raw;
    const dateB = ('date' in b) ? b.date.raw : b.field_date.raw;

    // Date A is before Date B
    if (dateA < dateB) {
      return -1;
    }
    // Date A is after Date B
    if (dateA > dateB) {
      return 1;
    }
    // Date A and Date B are equal
    return 0;
  });

  return (
    <section className={styles.hero}>
      <HeroSlideshow>
        {hero.map((slide, index) => {
          const images = {
            square: get(slide, 'relationships.field_image.max_1600_16_9.childImageSharp.largeRect'),
            rect: get(slide, 'relationships.field_image.max_1600_10_7.childImageSharp.rect'),
          };
          // Limit the number of slides that we'll render to 11 so that we don't
          // have to worry about the slide indicators overflowing the container.
          return index > 10 ? null : <HeroSlide
            key={slide.drupal_id}
            {...slide}
            images={images.square && images.rect ? images : null}
          />;
        })}
      </HeroSlideshow>
    </section>
  );
};

HomepageHero.propTypes = {
  data: PropTypes.object.isRequired
};

export default HomepageHero;
