import { useStaticQuery, graphql } from 'gatsby';

export const useHomePageGridData = () => {
  const { HomePageGridPromo } = useStaticQuery(
    graphql`
      query HomePageGridPromoQuery {
        HomePageGridPromo: taxonomyTermPromotion(
          drupal_id: { eq: "c3c90b8b-b885-465a-89ef-024cb949cecb" }
        ) {
          ...Promoted
        }
      }
    `
  );

  return HomePageGridPromo?.relationships?.field_promoted_content ?? [];
};
