import React from 'react';
import PropTypes from 'prop-types';

import Slide from '../../components/slide/slide';
import Slideshow from '../../components/slideshow/slideshow';
import styles from './promo.module.scss';
import { useInformationData } from '../../hooks/use-information-data';
import { useCurrentFilter } from '../../hooks/use-current-filter';
import { get } from '../../helpers/hooks-helpers';

const Promo = ({ content, tid }) => {
  const currentContent = useCurrentFilter(content);
  const information = useInformationData().filter((item) => item.promotions[tid]) || [];
  const data = information.shift() || {};
  const backgroundColor = get(data, 'backgroundColor', '#FFFFFF');
  return !currentContent.length ? null : (
    <div className={styles.promoContainer} style={{ backgroundColor }}>
      <Slideshow key={tid} title={data.title || ''}>
        {currentContent.map((slide) => {
          // Make sure there's actually an image before setting it.
          const image = get(slide, 'relationships.field_image.max_1600_16_9.childImageSharp.contentTeaser');
          if (!image) {
            return null;
          }
          const ctaVerbiageByType = {
            'node__place': `Discover ${slide.title}`,
            'node__event': 'View Event',
          };
          let subtitle = '';
          if (slide.internal.type === 'node__event') {
            subtitle = get(slide, 'date.format') || get(slide, 'date.full', '');
          }
          return (
            <Slide
              key={slide.drupal_id}
              title={slide.title}
              subtitle={subtitle}
              body={slide.body.summary}
              src={image.src}
              srcSet={image.srcSet}
              width={'100%'}
              alt={slide.field_image.alt}
              to={slide.path.alias}
              cta={ctaVerbiageByType[slide.internal.type] || 'Learn More'}
            />
          );
        })}
      </Slideshow>
    </div>
  );
};

Promo.propTypes = {
  tid: PropTypes.number.isRequired,
  content: PropTypes.arrayOf(PropTypes.object),
};

export default Promo;
