import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CTABand from '../components/cta-band/cta-band';
import ButtonBanner from '../components/button-banner/button-banner';
import DefaultLayout from '../components/default-layout/default-layout';
import Hero from '../components/hero/hero';
import HomePageGrid from '../components/home-page-grid/home-page-grid';
import SEO from '../components/seo/seo';
import UpcomingEvents from '../components/upcoming-events/upcoming-events';
import Promo from '../components/promo/promo';
import { useCurrentFilter } from '../hooks/use-current-filter';
import { filterExpiredNodes } from '../helpers/hooks-helpers';

const IndexPage = ({ data }) => {
  const upcomingEvents = useCurrentFilter(data.UpcomingEvents.edges, filterExpiredNodes);
  return (
    <DefaultLayout data={data}>
      <SEO
        title=""
        description="The heart of downtown Sandy Springs, GA."
        siteConfig={data.site.siteMetadata}
        pageUrl="/"
      />
      <Hero data={data} />
      <Promo
        tid={260}
        content={data.HomepagePromo.relationships.field_promoted_content}
      />

      <ButtonBanner
        titleTid={263} // to be updated
        buttonTid={264}
      />

      <HomePageGrid />
      <Promo
        tid={261}
        content={data.HomepageSecondaryPromo.relationships.field_promoted_content}
      />

      <UpcomingEvents data={upcomingEvents.slice(0, 4)} hideXL={true} />
    </DefaultLayout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query IndexQuery {
    HomepagePromo: taxonomyTermPromotion(
      drupal_id: { eq: "2b32703e-cc38-41ae-b348-a718a6ed2cd0" }
    ) {
      ...Promoted
    }
    HomepageSecondaryPromo: taxonomyTermPromotion(
      drupal_id: { eq: "d632b311-aff2-4962-9016-b1c2d1d82aa4" }
    ) {
      ...Promoted
    }
    HomepageHero: taxonomyTermPromotion(
      drupal_id: { eq: "894a0848-1de1-4c35-9d00-e3ca691cfe0f" }
    ) {
      ...Promoted
    }
    UpcomingEvents: allNodeEvent(
      sort: { fields: [field_date___value], order: ASC }
      filter: { field_private_event: { ne: true } }
    ) {
      edges {
        node {
          ...UpcomingEvents
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default IndexPage;
