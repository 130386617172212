/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

import styles from './hero-slide.module.scss';

const nodeCTA = (internal, title) => {
  let name = false;

  switch (internal.type) {
  case 'node__place':
    name = 'Discover';
    break;
  case 'node__news':
    name = 'Learn More';
    break;
  case 'node__event':
    name = 'View Event';
    break;
  case 'node__page':
    name = 'Learn More';
    break;
  }

  return name;
};

const HeroSlide = ({
  title,
  body,
  date,
  path,
  internal,
  images,
  relationships,
  field_image,
  field_summary_only
}) => (
  <section className={styles.slide} aria-label={title}>
    <div className={styles.imgContainer}>
      {images && (
        <React.Fragment>
          <img
            {...images.square}
            className={styles.largeImage}
            alt={field_image.alt}
          />
          <img
            {...images.rect}
            className={styles.smallImage}
            alt={field_image.alt}
          />
        </React.Fragment>
      )}
    </div>

    <div>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.body}>
        {body.summary.length > 300
          ? `${body.summary.substring(0, 300)}...`
          : body.summary}
      </div>

      <div className={styles.meta}>
        <div className={styles.metaInner}>
          {internal.type === 'node__event' && date && (
            // Only display dates for Events
            <React.Fragment>
              <p className={styles.dateSmall}>{date.shortformat ? date.shortformat : date.short}</p>
              <p className={styles.dateLarge}>
                {date.format ? date.format : date.full}
              </p>
            </React.Fragment>
          )}
          {date && (
            <p className={styles.location}>
              {relationships && relationships.venue && relationships.venue.name}
            </p>
          )}
          { field_summary_only !== true &&
          // ^^ The News content type has a boolean field to indicate "All of the information is included in the summary"
          // called field_summary_only. If that is checked (true), no button is printed.
          <Button
            className={styles.button}
            to={path.alias}
            aria-label={
              internal.type === 'node__news' || internal.type === 'node__place'
                ? `Read more about ${title}`
                : null
            }
          >
            {nodeCTA(internal, title)}
          </Button>
          }
        </div>
      </div>
    </div>
  </section>
);

HeroSlide.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  date: PropTypes.object,
  path: PropTypes.object.isRequired,
  internal: PropTypes.object,
  images: PropTypes.object,
  relationships: PropTypes.object,
  field_image: PropTypes.object,
  field_summary_only: PropTypes.bool
};

export default HeroSlide;
