import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import throttle from 'lodash/throttle';

import { ReactComponent as Arrow } from './assets/arrow.svg';

// Imports the CSS from slick carousel.
import 'slick-carousel/slick/slick.css';
// CSS Module styles for the slideshow.
import styles from './slideshow.module.scss';

export default class Slideshow extends Component {
  constructor() {
    super();

    this.state = {
      slidesToShow: 3,
      arrows: true,
      padding: '50px',
    };

    this.handleResize = throttle(this.handleResize.bind(this), 500);
  }

  // On mount, run through the resize logic and add a listener for future
  // resize events.
  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // On resize, update the number of slides that will show and hide the arrows
  // on mobile.
  handleResize() {
    const breakpoints = {
      medium: 576,
      large: 992
    };

    const width = typeof window === 'undefined' ? breakpoints.medium : window.innerWidth;

    if (width > breakpoints.large) {
      this.setState({
        slidesToShow: 3,
        arrows: true,
        padding: '50px',
      });
    }
    else if (width > breakpoints.medium) {
      this.setState({
        slidesToShow: 2,
        arrows: true,
        padding: '50px',
      });
    }
    else {
      this.setState({
        slidesToShow: 1,
        arrows: false,
        padding: '20px',
      });
    }
  }

  render() {
    // Settings for react-slick.
    // https://react-slick.neostack.com/docs/api#adaptiveHeight
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: this.state.slidesToShow,
      slidesToScroll: 1,
      centerMode: true,
      arrows: this.state.arrows,
      centerPadding: this.state.padding,
      onInit: this.handleResize.bind(this),
      // Provide a custom button.
      prevArrow: (
        <SlickButton>
          <span className="visually-hidden">Previous</span>
          <Arrow height="40" width="23" className={styles.prevArrow} />
        </SlickButton>
      ),
      // Provide a custom button.
      nextArrow: (
        <SlickButton>
          <span className="visually-hidden">Next</span>
          <Arrow height="40" width="23" />
        </SlickButton>
      )
    };
    let slides = this.props.children;
    // If there aren't enough slides to fill out the viewport, concat the array
    // of slides to prevent the slideshow from skewing the cards.
    while (slides.length < this.state.slidesToShow) {
      slides = slides.concat(this.props.children);
    }
    if (typeof this.props.title === 'object') {
      return (
        <section className={styles.slider}>
          <div className={styles.titleContainer}>
            {this.props.title}
          </div>
          <Slider {...settings}>{slides}</Slider>
        </section>
      );
    }

    return (
      <section aria-label={this.props.title} className={styles.slider}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{this.props.title}</h2>
        </div>
        <Slider {...settings}>{slides}</Slider>
      </section>
    );
  }
}

Slideshow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired
};

// Abstracting this component to prevent currentSlide and slideCount from
// being passed to <button>, which was triggering a warning from React.
const SlickButton = ({ currentSlide, slideCount, ...props }) => {
  return <button {...props}>{props.children}</button>;
};

SlickButton.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  children: PropTypes.node.isRequired
};
