import React from 'react';
import classnames from 'classnames';

import styles from './home-page-grid.module.scss';
import Link from '../link/link';
import { useHomePageGridData } from '../../hooks/use-home-page-grid-data';
import { useInformationData } from '../../hooks/use-information-data';
import { get } from '../../helpers/hooks-helpers';

const titleClass = classnames('heading-2', [styles.title]);
const HomePageGrid = () => {
  const data = useHomePageGridData();
  const information = useInformationData()
    // 262 is the term ID associated with the 'CitySprings Homepage: Info #1 -
    // Title' promotion term in Drupal to make sure we're limiting the data
    // to Info block title content only.
    .filter((item) => item.promotions[262]) || [];

  return !data.length ? null : (
    <section className={styles.hpGrid}>
      <h2 className={titleClass}>{get(information, '0.title', '')}</h2>
      <div className={styles.grid}>
        {data.slice(0, 3).map((item) => {
          // Make sure there's actually an image before setting it.
          const image = get(item, 'relationships.field_image.max_1600_16_9.childImageSharp.largeRect');
          if (!image) {
            return null;
          }

          const ctaVerbiageByType = {
            'node__place': `Discover ${item.title}`,
            'node__event': 'View Event',
          };
          return (
            <div className={styles.gridItem} key={item.drupal_id}>
              <img
                className={styles.img}
                alt={item.field_image.alt}
                src={image.src}
                srcSet={image.srcSet}
              />
              <h3 className={styles.itemTitle}>{item.title}</h3>
              <p className={styles.itemText}>
                {item.body.summary}
              </p>
              <Link className={styles.cta} to={item.path.alias}>
                {get(ctaVerbiageByType, item.internal.type, 'Learn More')}
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HomePageGrid;
