import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Link from '../link/link';

import styles from './slide.module.scss';

const Slide = ({
  title,
  subtitle,
  body,
  src,
  srcSet,
  width,
  height,
  imgResolutions,
  imgSizes,
  alt,
  to,
  cta
}) => (
  <div className={styles.slide}>
    {imgResolutions && (
      <Img
        className={styles.img}
        resolutions={imgResolutions.resolutions}
        alt={alt}
      />
    )}
    {imgSizes && (
      <Img className={styles.img} sizes={imgSizes.sizes} alt={alt} />
    )}
    {src && (
      <img
        className={styles.img}
        width={width}
        height={height}
        srcSet={srcSet}
        src={src}
        alt={alt}
      />
    )}
    <h3 className={styles.title}>{title}</h3>
    {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
    <p className={styles.body}>{body}</p>
    <Link className={styles.cta} to={to}>
      {cta}
    </Link>
  </div>
);

const requiredPropsCheck = (props, PropName, componentName) => {
  if (!props.src && !props.imgResolutions && !props.imgSizes) {
    return new Error(
      `Looks like you're missing a 'src', 'imgSizes' or 'imgResolutions' prop on the '${componentName}' component.`
    );
  }
};

Slide.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  body: PropTypes.string.isRequired,
  src: requiredPropsCheck,
  srcSet: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  imgResolutions: requiredPropsCheck,
  imgSizes: requiredPropsCheck,
  alt: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired
};

export default Slide;
