import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../button/button';
import MediaObject from '../media-object/media-object';

import styles from './upcoming-events.module.scss';

const UpcomingEvents = ({ data, className, hideXL }) => {
  const classes = classnames(className, styles.upcomingEvents, {
    [styles.hideXL]: hideXL
  });

  return (
    <section className={classes} aria-label="Upcoming Events">
      <h2 className={styles.title}>Upcoming Events</h2>
      <div className={styles.events}>
        {data &&
          data.map((item) => {
            const node = item.node;
            let image = null;
            let alt = null;
            let src = null;
            if (
              node.relationships.field_image &&
              node.relationships.field_image.max_1600_1_1
            ) {
              image =
                node.relationships.field_image.max_1600_1_1.childImageSharp;
              src = image.mediaObject.src;
              alt = node.field_image.alt;
            }
            let date = '';
            if (node.date) {
              date = node.date.format ? node.date.format : node.date.full;
            }
            return (
              <MediaObject
                className={styles.event}
                key={node.drupal_id}
                to={node.path.alias}
                title={node.title}
                alt={alt}
                src={src}
                text={`<span>Showing: <em>${date}</em></span>`}
              />
            );
          })}
      </div>
      <Button
        className={styles.button}
        borderStyle="square"
        textStyle="upper"
        to="/events"
      >
        View all events at City Springs
      </Button>
    </section>
  );
};

UpcomingEvents.propTypes = {
  data: PropTypes.array,
  // Pass in a class name from a parent component.
  className: PropTypes.string,
  // Optionally hide the component on XL screens.
  hideXL: PropTypes.bool
};

export default UpcomingEvents;
